import { Button } from "../../components/ui/button";
import { Separator } from "../../components/ui/separator";
import config from "../../services/config";
import generateHash from "../../services/generateHash";
import React, { useEffect, useState } from "react";
import { set } from "../../services/cache";
import { dateAdd } from "../../services/utils";
import { useNavigate } from "react-router-dom";
import plvLogo from "../../assets/plvLogo.svg";
import fedidLogo from "../../assets/fedidLogo.svg";
import apiService from "../../services/apiService";

export function Login() {
  const [fedidUrl, setFedidUrl] = useState("");

  const navigate = useNavigate();
  const code = new URL(window.location.href).searchParams.get("code");

  const fetchMemberInfo = async (token) => {

    try {
      const result = await apiService.getMember(`/v1/user/memberpro-info`,token);
      set({
        key: "claims",
        data: result.data[0],
      });
      set({
        key: "type",
        data: "supplier"
      });
      navigate('/homepage');
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    
  }
  useEffect(() => {
    const crypto = generateHash();

    set({
      key: config.FedidCodeVerifier.cacheKey,
      data: crypto,
      expires: dateAdd(new Date(), "second", 7199).toISOString(),
    });

    let url = `${process.env.REACT_APP_LOGIN_URL}&code_challenge=${crypto}&code_challenge_method=plain`;

    setFedidUrl(url);
    localStorage.setItem("getMemberUrl", false);
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      if (code) {
        try {
          const result = await apiService.post(`/v1/user/login/${code}`);
          if (result.access_token) {
            localStorage.setItem('access_token', result.access_token);
            fetchMemberInfo(result.access_token);
          } else {
            console.error('Access token not found');
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchToken();
  }, [code, navigate]);

  return (
    <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px] h-[100vh] xs:content-center">
      <div className="hidden bg-muted lg:block content-center">
        <div className="flex justify-center">
          <img src={plvLogo} alt="" />
        </div>
      </div>
      <div className="flex items-center justify-center py-12 ">
        <div className="mx-auto grid w-[350px] gap-6">
          <img
            src={plvLogo}
            alt="logo"
            className="absolute top-[-25px] left-[-30px] md:hidden"
          />
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Hoş Geldin</h1>
          </div>
          <div className="grid gap-4">

            <Button className="w-full h-12" onClick={() =>navigate("/supplier-login")} >
              MemberPro İle Giriş Yap
            </Button>

            <div className="flex justify-between items-center text-[#999999]">
              <Separator className="my-4 w-2/5" />
              veya
              <Separator className="my-4 w-2/5" />
            </div>

            <Button
              className="w-full h-12 gap-2"
              onClick={() => (window.location.href = fedidUrl)}
            >
              <img src={fedidLogo} alt="" />
              FedID İle Giriş Yap
            </Button>
          </div>
          <div className="mt-4 text-center text-sm">
            Hesabın yok mu?{" "}
            <a
              href="https://b2b.decathlon.com.tr"
              className="underline"
              target="blank"
            >
              Kayıt ol
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
