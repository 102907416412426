import React from "react";

const NotFoundPage = () => {

  return (

    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundImage: `url(https://contents.mediadecathlon.com/s1012505/k$907fcf5b478a78b926268c4bbe944f22/Mobile22.png)`,
        width: "100%",
        height: "100vh",
        position: "absolute",
        top: "0",
        left: "0"
      }}
    >
      <div class="flex flex-col ml-8 mt-24 md:ml-24 md:mt-40">
        <a class="flex" href="https://decathlon.com.tr/" tabindex="0" aria-label="Ana sayfaya git">
            <img alt="not-found" class="brand-logo" className="w-[220px]" src="https://contents.mediadecathlon.com/b101216/k$8846f6a168e79c85734dad3defd320ac/defaut.svg?format=auto"/>
        </a>

        <p class="mt-16 mb-4 font-bold text-white text-xl md:text-3xl">Bir şeyler ters gitti !</p>
        <p class="text-base font-normal text-white md:text-2xl">Hata 404: Üzgünüz, aradığınız sayfayı bulamadık.</p>

    </div>
        <button className="ml-8 md:ml-24 mt-4 p-3 bg-[#3643BA] text-white" onClick={()=> window.location.pathname = "/homepage"}>
          Ana Sayfa
        </button>

    </div>
  );
};

export default NotFoundPage;
