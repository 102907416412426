import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../pages/login/Login";
import { FedidLogin } from "../pages/login/FedidLogin";
import { SupplierLogin } from "../pages/login/SupplierLogin";
import Layout from "../pages/Layout";
import HomePage from "../pages/home/HomePage";
import OrderDetail from "../pages/details/OrderDetail";
import { Toaster } from "../components/ui/sonner";
import PrivateRoute from "./PrivateRoute";
import NotFoundPage from "../pages/warning/NotFoundPage"; 

const MasterRouter = () => {
  const [showLayout, setShowLayout] = useState(false);

  useEffect(() => {
    const fetchCache = async () => {
      const pathname = window.location.pathname;
      
      if (
        pathname === "/homepage" ||
        pathname.startsWith("/order/")
      ) {
        setShowLayout(true);
      } else {
        setShowLayout(false);
      }
    };

    fetchCache();

    const interval = setInterval(fetchCache, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Router>
      {showLayout && <Layout />} 
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<FedidLogin />} />
        <Route exact path="/supplier-login" element={<SupplierLogin />} />
        <Route path="/homepage" element={<PrivateRoute element={<HomePage />} />} />
        <Route path="/order/:orderNumber" element={<PrivateRoute element={<OrderDetail />} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Toaster />
    </Router>
  );
};

export default MasterRouter;
