import React, { useState, useEffect } from "react";
import { cn } from "../../lib/utils";
import PhotoGallery from "./PhotoGallery";
import apiService from "../../services/apiService";
import { Button } from "./button";
import { toast } from "sonner";

const Textarea = ({ orderDetailId, onRefreshData, orderImages }) => {
    const [content, setContent] = useState('');
    const [images, setImages] = useState([]);

    const [base64DataList, setBase64DataList] = useState([]);
    const [imageSrcList, setImageSrcList] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFilesToShow, setSelectedFilesToShow] = useState([]);
    const closeDialog = document.getElementsByClassName("closeDialog")[0];

    useEffect(() => {
        const newImageSrcList = [];

        if (base64DataList && base64DataList.length > 0) {
            base64DataList.forEach((base64Data) => {
                if (base64Data) {
                    const binaryData = atob(base64Data);
                    const imageData = new Uint8Array(binaryData.length);

                    for (let i = 0; i < binaryData.length; i++) {
                        imageData[i] = binaryData.charCodeAt(i);
                    }

                    const blob = new Blob([imageData], { type: "image/jpeg" });
                    const imageUrl = URL.createObjectURL(blob);

                    newImageSrcList.push(imageUrl);
                }
            });
        }

        setImageSrcList(newImageSrcList);

    }, [base64DataList]);

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        if ((selectedFiles.length <= 4 || selectedFilesToShow <= 4) && selectedFiles.length + selectedFilesToShow.length <= 4) {
            setSelectedFilesToShow(prevSelectedFiles => [...prevSelectedFiles, ...files]);
            setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, ...files]);
        } else {
            alert('En fazla 3 görsel ekleyebilirsiniz.')
        }
    };

    const deletePhoto = (index) => {
        const updatedSelectedFilesToShow = [...selectedFilesToShow];
        updatedSelectedFilesToShow.splice(index, 1);
        setSelectedFilesToShow(updatedSelectedFilesToShow);

        const updatedSelectedFiles = [...selectedFiles];
        updatedSelectedFiles.splice(index, 1);
        setSelectedFiles(updatedSelectedFiles);
    };

    useEffect(() => {
        return () => {
            images.forEach(image => URL.revokeObjectURL(image.url));
        };
    }, [images]);

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const convertFilesToBase64 = async (files) => {
        const base64Files = await Promise.all(files.map(async (file) => ({
            fileName: file.name,
            content: await toBase64(file)
        })));
        return base64Files;
    };

    const handleAddComment = async (e) => {
        const base64Files = await convertFilesToBase64(selectedFilesToShow);
        const body = {
            images: base64Files,
            comments: [
                {
                    "text": `${content}`
                }
            ],
        };

        if (selectedFilesToShow.length > 0 || content)
            try {
                const result = await apiService.post(`/v1/orderdetail/${orderDetailId}/comments-and-images`, body);
                if (result.isSuccess) {
                    toast("Yorum Eklendi", {
                        description: `Yorum eklendi.`,
                        cancel: {
                            label: "Kapat",
                        },
                    });
                    closeDialog.click();
                    onRefreshData();
                } else {
                    console.error("Error Message:", result.errorMessage);
                    toast("Yorum Ekleme Hatası", {
                        description: `Yorum eklenirken hata oluştu.`,
                        cancel: {
                            label: "Kapat",
                        },
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
    };

    return (
        <div className="px-2 py-1 m-3 text-center">
            <div className="relative">
                <textarea
                    className={cn("flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50")}
                    placeholder="Yorum yazın..."
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    rows={6}
                />
                <input
                    type="file"
                    id="commentPhoto"
                    accept="image/*"
                    multiple
                    onChange={handleImageChange}
                    className="hidden"
                    disabled={selectedFiles.length >= 3 || orderImages >= 3 || selectedFiles.length + orderImages >= 3}
                />
                <label htmlFor="commentPhoto" className="underline flex items-center gap-1 font-medium absolute right-3 bottom-3 cursor-pointer">
                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.8002 15.2H10.8002C10.5002 15.2 10.3002 15 10.3002 14.7V9.70005C10.3002 9.40005 10.5002 9.20005 10.8002 9.20005H19.8002C20.1002 9.20005 20.3002 9.40005 20.3002 9.70005V14.7C20.2002 15 20.0002 15.2 19.8002 15.2Z" fill="#101010" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.0502 9.95005V14.45H19.5502V9.95005H11.0502ZM9.90737 8.80722C10.1545 8.56013 10.4798 8.45005 10.8002 8.45005H19.8002C20.1206 8.45005 20.4459 8.56013 20.693 8.80722C20.9401 9.0543 21.0502 9.37961 21.0502 9.70005V14.8218L21.0117 14.9372C20.9319 15.1766 20.8023 15.4086 20.618 15.5929C20.4373 15.7736 20.1574 15.95 19.8002 15.95H10.8002C10.4798 15.95 10.1545 15.84 9.90737 15.5929C9.66028 15.3458 9.5502 15.0205 9.5502 14.7V9.70005C9.5502 9.37961 9.66028 9.0543 9.90737 8.80722Z" fill="#101010" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.4502 1.55005V9.65005H8.0002V11.15H2.2002C1.87976 11.15 1.55445 11.04 1.30737 10.7929C1.06028 10.5458 0.950195 10.2205 0.950195 9.90005V1.023L1.02937 0.864638C1.11996 0.683459 1.25452 0.501392 1.4246 0.355606C1.58617 0.217121 1.85375 0.0500488 2.2002 0.0500488H17.2002C17.5206 0.0500488 17.8459 0.160134 18.093 0.407219C18.3401 0.654304 18.4502 0.979614 18.4502 1.30005V7.00005H16.9502V1.55005H2.4502Z" fill="#101010" />
                    </svg>
                    Görsel Ekle
                </label>
            </div>
            <div className="mt-3 flex gap-2 justify-center">
                {(selectedFilesToShow.length > 0 && selectedFilesToShow.length <= 3) && (
                    <PhotoGallery
                        photos={selectedFilesToShow}
                        onDeletePhoto={deletePhoto}
                    />
                )}
            </div>
            <Button className="rounded-3xl px-10 bg-[#3643BA] mt-3" onClick={handleAddComment} disabled={selectedFilesToShow.length === 0 && !content}>Kaydet</Button>
        </div>
    );
};


export default Textarea;
