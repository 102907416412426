import React, { useState, useEffect } from "react";
import { Dialog } from "../../components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import apiService from "../../services/apiService";
import { toast } from "sonner";

import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const OrderItemStatus = ({ OrderItemId, orderItemStatusId }) => {
  const [selectedStatusId, setSelectedStatusId] = useState();
  const [selectedOrderItem, setSelectedOrderItem] = useState();
  const [orderItem, setOrderItem] = useState(orderItemStatusId);

  useEffect(() => {
    const handleOrderEnum = async (e) => {
      const body = {
        id: OrderItemId,
        status: selectedStatusId,
      };

      try {
        const result = await apiService.post(
          `/v1/orderdetail/set-status`,
          body
        );
        if (result.isSuccess) {
          toast("Durum Güncellendi", {
            description: `Durum Güncellendi.`,
            cancel: {
              label: "Kapat",
            },
          });
        } else {
          console.error("Error Message:", result.errorMessage);
          toast("Durum Güncelleme Hatası", {
            description: `Durum Güncellenirken hata oluştu.`,
            cancel: {
              label: "Kapat",
            },
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (selectedOrderItem !== undefined && selectedStatusId !== undefined) {
      handleOrderEnum();
    }
  }, [selectedStatusId, selectedOrderItem]);

  if (OrderItemId) {
    return (
      <>
        <Dialog>
          <Select
            onValueChange={(e) => {
              setSelectedStatusId(e);
              setSelectedOrderItem(e);
            }}
            value={
              selectedOrderItem
                ? selectedOrderItem
                : orderItem !== 0
                ? orderItem
                : ""
            }
            className="p-5"
          >
            <SelectTrigger className="w-auto rounded-3xl h-7 bg-[#EFF4FE] text-[#444444] border-none relative focus:ring-[#3643BA]">
              <SelectValue placeholder="PLV Durumu seçin" />
            </SelectTrigger>

            <SelectContent className="rounded-3xl py-2 border-[#3643BA]">
              <SelectGroup>
                <SelectItem value={1}>Kullanıma Uygun</SelectItem>
                <SelectItem value={2}>Eksik</SelectItem>
                <SelectItem value={3}>Hasarlı</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </Dialog>
      </>
    );
  }

  return <div></div>;
};

export default OrderItemStatus;
